import { call, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import types from './types';

const login = function* () {
  yield put(actions.loginRequested());
  try {
    const profile = yield call(window.auth.getProfile);
    if (typeof profile === 'object' && Object.keys(profile).length) {
      yield put(actions.loginSucceeded(profile));
    } else {
      yield put(actions.loginFailed());
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
};

const watchLogin = function* () {
  yield takeEvery(types.LOGIN, login);
};

export default [watchLogin];
