import types from './types';

const initialState = {
  login: {
    profile: {},
    isProfile: false,
    inProgress: false,
    didInvalidate: false,
    lastUpdate: null,
    loggedIn: false,
    error: null
  }
};

// prettier-ignore
export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: true,
          loggedIn: false,
        },
      };
    case types.LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: true,
          lastUpdate: Date.now(),
          profile: action.profile,
          isProfile: !!Object.entries(action.profile).length,
        },
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          inProgress: false,
          loggedIn: false,
          lastUpdate: Date.now(),
          error: action.error,
        },
      };

    default:
      return state;
  }
}
