const LOGIN = 'app/authentication/LOGIN';
const LOGIN_REQUESTED = 'app/authentication/LOGIN_REQUESTED';
const LOGIN_SUCCEEDED = 'app/authentication/LOGIN_SUCCEEDED';
const LOGIN_FAILED = 'app/authentication/LOGIN_FAILED';

export default {
  LOGIN,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED
};
