import types from './types';

export const login = (redirect) => ({
  type: types.LOGIN,
  redirect
});

export const loginRequested = () => ({
  type: types.LOGIN_REQUESTED
});

export const loginSucceeded = (profile) => ({
  type: types.LOGIN_SUCCEEDED,
  profile
});

export const loginFailed = (error) => ({
  type: types.LOGIN_FAILED,
  error
});

export default {
  login,
  loginRequested,
  loginSucceeded,
  loginFailed
};
